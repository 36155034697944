import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";
import { nanoid } from "nanoid";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that fetches data
    () =>
      supabase
        .from("users")
        .select(`*, customers ( * ), usage ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid },
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * ), usage ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}

/**** RULES ****/

// Fetch item data
export function useRule(id) {
  return useQuery(
    ["rule", { id }],
    () => supabase.from("rules").select().eq("id", id).single().then(handle),
    { enabled: !!id },
  );
}

export function getRule(id) {
  return supabase.from("rules").select().eq("id", id).single().then(handle);
}

export async function deleteTemplate(userId, templates, delTemplateIdx) {
  // remove template at index
  let newTemplates = [...templates];
  newTemplates.splice(delTemplateIdx, 1);
  // update user templates
  const response = supabase
    .from("users")
    .update({ templates: newTemplates })
    .eq("id", userId)
    .then(handle);
  await client.invalidateQueries(["user", { uid: userId }]);
  return response;
}

const handleTagFilter = (query, tag, range, querySearch) => {
  let filteredQuery = query;

  // Handle tag filtering
  if (tag === null) {
    filteredQuery = filteredQuery.is("tag", null);
  } else if (tag !== "*") {
    filteredQuery = filteredQuery.eq("tag", tag);
  }

  // Handle search filtering
  if (querySearch && querySearch.length > 0) {
    filteredQuery = filteredQuery.or(
      `name.ilike.%${querySearch}%,description.ilike.%${querySearch}%,accessGroups.cd.{%${querySearch}%}`,
    );
  }

  // Handle range filtering
  if (range) {
    filteredQuery = filteredQuery.range(range[0], range[1]);
  }

  return filteredQuery;
};

// Fetch all rules by owner
export function useRulesByOwner(
  owner,
  range = null,
  querySearch = null,
  tag = null,
) {
  return useQuery(
    ["rules", { owner, range, querySearch, tag }],
    () => {
      const baseQuery = supabase
        .from("rules")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false });

      return handleTagFilter(baseQuery, tag, range, querySearch).then(handle);
    },
    { enabled: !!owner },
  );
}

export function useRulePreviewsByOwner(
  owner,
  range = null,
  querySearch = null,
  tag = null,
) {
  return useQuery(
    ["rule-previews", { owner, range, querySearch, tag }],
    () => {
      const baseQuery = supabase
        .from("rules")
        .select(
          "id, owner, name, description, createdAt, updatedAt, requestSchema, responseSchema, sampleRequest, sampleResponse, updatedBy, slug, published, publishedAt, no_conditions, groups, accessGroups, form, history, settings, tag",
          range ? { count: "exact" } : undefined,
        )
        .eq("owner", owner)
        .order("createdAt", { ascending: false });

      return handleTagFilter(baseQuery, tag, range, querySearch).then(handle);
    },
    { enabled: !!owner },
  );
}

export function getRulePreviewsByTag(owner, tag) {
  return supabase
    .from("rules")
    .select(
      "id, owner, name, description, createdAt, updatedAt, requestSchema, responseSchema, sampleRequest, sampleResponse, updatedBy, slug, published, publishedAt, no_conditions, groups, accessGroups, form, history, settings, tag",
    )
    .eq("owner", owner)
    .eq("tag", tag)
    .order("createdAt", { ascending: false })
    .then(handle);
}

export function useRulePreviewsByIds(owner, ids) {
  return useQuery(
    ["rule-previews-ids", { owner, ids }],
    () =>
      supabase
        .from("rules")
        .select(
          "id, owner, name, description, createdAt, updatedAt, updatedBy, slug, published, publishedAt, settings, accessGroups",
        )
        .in("id", ids)
        .then(handle),
    { enabled: !!owner && !!ids },
  );
}

export function usePublishedRulesByOwner(
  owner,
  range = null,
  querySearch = null,
  tag = null,
) {
  return useQuery(
    ["published-rules", { owner, querySearch }],
    () => {
      if (!querySearch) {
        return supabase
          .from("rules")
          .select("*")
          .eq("owner", owner)
          .is("published", true)
          .limit(50)
          .order("publishedAt", { ascending: false })
          .then(handle);
      } else {
        return supabase
          .from("rules")
          .select("*")
          .eq("owner", owner)
          .is("published", true)
          .or(`name.ilike.%${querySearch}%,description.ilike.%${querySearch}%`)
          .order("publishedAt", { ascending: false })
          .limit(50)
          .then(handle);
      }
    },
    { enabled: !!owner },
  );
}

// Create a new item
export async function createRule(data) {
  const response = await supabase
    .from("rules")
    .insert([data])
    .select()
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["rules"]);
  await client.invalidateQueries(["rule-previews"]);
  return response;
}

// Duplicate an item
export async function duplicateRule(id) {
  const response = await supabase
    .from("rules")
    .select()
    .eq("id", id)
    .single()
    .then(({ data }) => {
      delete data.id;
      return supabase
        .from("rules")
        .insert([
          {
            ...data,
            name: `${data.name} (Copy)`,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            slug: nanoid(10),
            published: false,
            publishedAt: null,
            published_requestSchema: null,
            published_responseSchema: null,
            published_conditions: null,
            published_groups: null,
            history: [],
          },
        ])
        .single()
        .then(handle);
    });
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["rules"]);
  await client.invalidateQueries(["rule-previews"]);
  return response;
}

// Update an item
export async function updateRule(id, data) {
  // if data.tag is not null
  // we want to use the tag's accessGroups
  // else we want to reset the accessGroups to empty

  const response = await supabase
    .from("rules")
    .update(data)
    .eq("id", id)
    .select()
    .maybeSingle()
    .then(handle);

  if (data.tag) {
    // first get the rule owner user
    const user = await getUser(response.owner);
    const tags = user.tags || [];
    const tag = tags.find((t) => t.id === data.tag);
    if (tag) {
      const accessGroups = tag.accessGroups || [];
      await supabase
        .from("rules")
        .update({ accessGroups })
        .eq("id", id)
        .then(handle);
    }
  }

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["rule", { id }]),
    client.invalidateQueries(["rules"]),
    client.invalidateQueries(["rule-previews"]),
    client.invalidateQueries(["rule-previews-ids"]),
    client.invalidateQueries(["published-rules"]),
  ]);
  return response;
}

export async function invalidateRule(id) {
  await Promise.all([client.invalidateQueries(["rule", { id }])]);
}

export async function invalidateRules() {
  await Promise.all([
    client.invalidateQueries(["rules"]),
    client.invalidateQueries(["rule-previews"]),
    client.invalidateQueries(["rule-previews-ids"]),
    client.invalidateQueries(["published-rules"]),
  ]);
}

// Delete an item
export async function deleteRule(id) {
  const response = await supabase
    .from("rules")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["rule", { id }]),
    client.invalidateQueries(["rules"]),
    client.invalidateQueries(["rule-previews"]),
  ]);
  return response;
}

/**** FLOWS ****/

// Fetch item data
export function useFlow(id) {
  return useQuery(
    ["flow", { id }],
    () => supabase.from("flows").select().eq("id", id).single().then(handle),
    { enabled: !!id },
  );
}

// Fetch all rules by owner
export function useFlowsByOwner(owner) {
  return useQuery(
    ["flows", { owner }],
    () =>
      supabase
        .from("flows")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner },
  );
}

export function useFlowPreviewsByOwner(owner) {
  return useQuery(
    ["flow-previews", { owner }],
    () =>
      supabase
        .from("flows")
        .select(
          "id, owner, name, description, createdAt, updatedAt, updatedBy, origin, slug, published, publishedAt, settings, accessGroups",
        )
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner },
  );
}

// Create a new item
export async function createFlow(data) {
  const response = await supabase
    .from("flows")
    .insert([data])
    .select()
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["flows"]);
  await client.invalidateQueries(["flow-previews"]);
  return response;
}

// Duplicate an item
export async function duplicateFlow(id) {
  const response = await supabase
    .from("flows")
    .select()
    .eq("id", id)
    .single()
    .then(({ data }) => {
      delete data.id;
      return supabase
        .from("flows")
        .insert([
          {
            ...data,
            name: `${data.name} (Copy)`,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            slug: nanoid(10),
            published: false,
            publishedAt: null,
            published_graph: null,
            history: [],
          },
        ])
        .single()
        .then(handle);
    });
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["flows"]);
  await client.invalidateQueries(["flow-previews"]);
  return response;
}

// Update an item
export async function updateFlow(id, data) {
  const response = await supabase
    .from("flows")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["flow", { id }]),
    client.invalidateQueries(["flows"]),
    client.invalidateQueries(["flow-previews"]),
  ]);
  return response;
}

// Delete an item
export async function deleteFlow(id) {
  const response = await supabase
    .from("flows")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["flow", { id }]),
    client.invalidateQueries(["flows"]),
    client.invalidateQueries(["flow-previews"]),
  ]);
  return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) throw response.error;
  if (response.count)
    return {
      data: response.data,
      count: response.count,
    };
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
